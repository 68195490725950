/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/select2@4.0.4/dist/js/select2.min.js
 * - /npm/autonumeric@4.1.0-beta.9/dist/autoNumeric.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/moment@2.19.1/moment.min.js
 * - /npm/moment@2.19.1/min/locales.min.js
 * - /npm/eonasdan-bootstrap-datetimepicker@4.17.47/build/js/bootstrap-datetimepicker.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
